import Axios from '../../axios';
import { errorHandler } from './errorHandler';

export const Apis = {
    addCategory: 'api/addcategory',
    getCategory: 'api/getParent',
    deleteCategory: 'api/deletecategory',
    updateCategory: 'api/updatecategory',
    getSubCategory: 'api/get_subCats',
    getAds: 'api/alladvertise',
    insertImage: 'api/insertImage',
    specificProfile: 'api/specProfile',
    userProfileUpdate: 'api/profileUpdate',
    login: 'api/signIn',
    users: 'api/users',
    specificUser: 'api/specificuser',
    deleteUser: 'api/deluser',
    change_password: 'api/change_password',

};

export const headers = {
    'content-type': 'application/json',
};


export const get = async (endPoint, token) => {
    try {
        const result = await Axios.get(endPoint, { headers: { 'x-access-token': token } });
        return result;
    } catch (e) {
        throw errorHandler(e);
    }
};

export const post = async (endPoint, data, token) => {
    try {
        const result = await Axios.post(endPoint, data, { headers: { 'x-access-token': token } });
        return result;
    } catch (e) {
        throw errorHandler(e);
    }
};
export const put = async (endPoint, data, token) => {
    try {
        const result = await Axios.put(endPoint, data, { headers: { 'x-access-token': token } });
        return result;
    } catch (e) {
        throw errorHandler(e);
    }
};
export const deleted = async (endPoint, token) => {
    try {
        const result = await Axios.delete(endPoint, { headers: { 'x-access-token': token } });
        return result;
    } catch (e) {
        throw errorHandler(e);
    }
};


