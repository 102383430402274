import Drawer from '@mui/material/Drawer';

import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import dp from '../assets/dp.jpg'
import DashboardIcon from '@mui/icons-material/Dashboard';
import { Avatar, Box, Collapse, ListItemButton, Typography } from '@mui/material';
import { ExpandLess, ExpandMore, StarBorder } from '@mui/icons-material';
import ListItemIcon from '@mui/material/ListItemIcon';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import ReceiptIcon from '@mui/icons-material/Receipt';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import useAuth from './../hooks/useAuth';
const drawerWidth = 260;

const dashboardItems = [
  {
    id: 1,
    title: "Dashboard",
    subtitles: [
      { id: 1, name: "Analysis", icon: <AnalyticsIcon />, path: '/' },
    ],
    icon: <DashboardIcon />
  },
  {
    id: 2,
    title: "User",
    subtitles: [
      { id: 1, name: "User List", icon: <AnalyticsIcon />, path: '/users' },
      // { id: 2, name: "Create User", icon: <ReceiptIcon />,path:'/' }
    ],
    icon: <PersonIcon />
  },
  {
    id: 3,
    title: "Category",
    subtitles: [
      { id: 1, name: "Category List", icon: <AnalyticsIcon />, path: '/category' },
      { id: 2, name: "Create Category", icon: <ReceiptIcon />, path: '/category/create-category' },
      { id: 3, name: "Create SubCategory", icon: <ReceiptIcon />, path: '/category/create-subcategory' }
    ],
    icon: <PersonIcon />
  },
  {
    id: 4,
    title: "Manage Ads",
    subtitles: [
      { id: 1, name: "View Ads", icon: <VisibilityOutlinedIcon />, path: '/ads' },
      // { id: 2, name: "Create Ads", icon: <AddCircleOutlineOutlinedIcon />,path:'/ads/create-ads' },

    ],
    icon: <InventoryOutlinedIcon />
  },

];
export default function Sidenav() {
  const [open, setOpen] = useState({});
  const { user, signOut } = useAuth();


  const logOut = () => {
    signOut()
  };

  const handleClick = (id) => {

    setOpen((prevState => ({ ...prevState, [id]: !prevState[id] })))
  };
  return (

    <Drawer
      PaperProps={{ style: { height: "96%" } }}
      sx={{
        width: drawerWidth,

        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
          margin: '15px',
          background: '#293042',
          color: '#fff',
          borderRadius: '13px'
        },
      }}
      variant="permanent"
      anchor="left"
    >

      <List>
        <Box sx={{ textAlign: "center" }}>
          <ListItemButton onClick={() => handleClick('Profile')}>
            <Typography sx={{ fontSize: "30px" }}>
              <ListItemIcon sx={{ color: 'white' }}>
                <Avatar alt={user?.name} src={user?.image} sx={{}} />
              </ListItemIcon>
            </Typography>
            <ListItemText primary={user?.name} />
            {open['Profile'] ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
        </Box>
        <Collapse in={open['Profile']} timeout="auto" unmountOnExit>
          <List key={'Profile'} component="div" disablePadding>
            <ListItemButton component={Link} to='/account/profile' sx={{ pl: 2, fontSize: "30px" }}>
              <ListItemIcon sx={{ color: 'white' }}>
                <StarBorder />
              </ListItemIcon>
              <ListItemText primary={'My Profile'} />
            </ListItemButton>
            <ListItemButton sx={{ pl: 2 }} onClick={logOut}>
              <ListItemIcon sx={{ color: '#fff' }}>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary={'Logout'} />
            </ListItemButton>
          </List>
        </Collapse>
      </List>
      <Divider sx={{ bgcolor: "#5F5F65" }} />

      <List component="nav">
        {dashboardItems.map((item) => {
          return (
            <List key={item.id}>
              <ListItemButton onClick={() => handleClick(item.id)}
              >
                <ListItemIcon sx={{ color: 'white' }}>
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.title} sx={{ fontSize: "20px" }} />
                {open[item.id] ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={open[item.id]} timeout="auto" unmountOnExit>
                {item.subtitles.map((subitem, i) => {

                  return (
                    <List key={i} component="div" disablePadding>
                      <ListItemButton component={Link} to={subitem.path} sx={{ pl: 3 }}>
                        <ListItemIcon sx={{ color: 'white' }}>
                          {subitem.icon}
                        </ListItemIcon>
                        <ListItemText primary={subitem.name} />
                      </ListItemButton>
                    </List>
                  )
                })}
              </Collapse>
            </List>
          )
        })}


      </List>
    </Drawer>

  );
}
