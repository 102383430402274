import { Box, Breadcrumbs, Button, CircularProgress, IconButton, Modal, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Layout from '../../Layout/Layout'
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Service } from './../../config/service';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    borderRadius: '13px',
    p: 4,
};
const Ads = () => {
    const [ads, setAds] = useState([])
    const token = localStorage.getItem('token')
    const [open, setOpen] = useState(false);
    async function fetchData() {
        const { Data } = await Service.getAds(token);

        setAds(Data)
    }
    useEffect(() => {

        fetchData()

    }, [])
    const handleDelete = async (item) => {
        setOpen(true)
        // await setAds(item)


    };
    const handleClose = () => setOpen(false);
    const adsData = [
        { id: 1, title: 'Commercial Plaza', image: 'https://images.unsplash.com/photo-1600585154340-be6161a56a0c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8cHJvcGVydHl8ZW58MHx8MHx8&w=1000&q=80', category: 'Property', subCategory: 'House', phoneno: '03313232848', image: 'https://images.unsplash.com/photo-1600585154340-be6161a56a0c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8cHJvcGVydHl8ZW58MHx8MHx8&w=1000&q=80', price: '250,000,000', userName: 'Zeeshan' },
        { id: 2, title: 'Commercial Plaza', image: 'https://images.unsplash.com/photo-1600585154340-be6161a56a0c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8cHJvcGVydHl8ZW58MHx8MHx8&w=1000&q=80', category: 'Property', subCategory: 'House', phoneno: '03313232848', price: '250,000,000', userName: 'Zeeshan' },
        { id: 3, title: 'Commercial Plaza', image: 'https://images.unsplash.com/photo-1600585154340-be6161a56a0c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8cHJvcGVydHl8ZW58MHx8MHx8&w=1000&q=80', category: 'Property', subCategory: 'House', phoneno: '03313232848', price: '250,000,000', userName: 'Zeeshan' },
    ]
    return (
        <Layout >
            <div role="presentation" >
                <Breadcrumbs aria-label="breadcrumb"  >
                    <Link underline="hover" color="inherit" to="/">
                        Home
                    </Link>
                    <Link
                        underline="hover"
                        color="inherit"
                        to="/ads"
                    >
                        Ads
                    </Link>
                    <Typography color="text.primary">Ads List</Typography>
                </Breadcrumbs>
            </div>
            <TableContainer component={Paper} sx={{ my: 5 }}>
                <Table  >
                    <TableHead >
                        <TableRow>
                            <TableCell style={{ fontWeight: '600' }}>#</TableCell>
                            <TableCell align="center" style={{ fontWeight: '600' }} >Title</TableCell>
                            {/* <TableCell align="center" style={{fontWeight:'600'}}>Category</TableCell> */}
                            <TableCell align="center" style={{ fontWeight: '600' }}>Sub Category</TableCell>
                            <TableCell align="center" style={{ fontWeight: '600' }}>Price</TableCell>
                            <TableCell align="center" style={{ fontWeight: '600' }}>User</TableCell>

                            <TableCell align="center" sx={{ pr: 4, fontWeight: '600' }}>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {ads ?
                            ads.map((item, i) => {
                                return (
                                    <TableRow  key={i}>
                                        <TableCell align="center" component="th" scope="row">
                                            {i + 1}
                                        </TableCell>
                                        <TableCell align="center">
                                            {item.title}
                                        </TableCell>
                                        {/* <TableCell>
                                       {item.category}
                                    </TableCell> */}
                                        <TableCell align="center">
                                            {item.cat_name}
                                        </TableCell>
                                        <TableCell align="center">
                                            {item.price}
                                        </TableCell>
                                        <TableCell align="center">
                                            {item.user_name}
                                        </TableCell>



                                        <TableCell align="right">

                                            {/* <IconButton aria-label="edit" size="small"
                                         component={Link} 
                                         to={`/ads/edit-ads/${item.id}`}
                                         state={item} >
                                            <EditIcon />
                                        </IconButton> */}
                                            <IconButton aria-label="delete" size="small"
                                                onClick={() => handleDelete(item)} >
                                                <DeleteIcon />

                                            </IconButton>

                                            <Modal
                                                open={open}
                                                onClose={handleClose}
                                                aria-labelledby="modal-modal-title"
                                                aria-describedby="modal-modal-description"
                                            >
                                                <Box sx={style} >
                                                    <Typography variant="h5" component="h2"
                                                        sx={{ textAlign: 'center' }}>
                                                        Are You Sure To Delete ?
                                                    </Typography>

                                                    <Stack spacing={2} direction="row"
                                                        sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>

                                                        <Button variant="outlined" onClick={handleClose}>No</Button>
                                                        <Button variant="outlined" color="error">Yes</Button>
                                                    </Stack>
                                                </Box>
                                            </Modal>
                                        </TableCell>

                                    </TableRow>

                                )
                            }) :
                            <TableRow>
                                <TableCell colspan={6} align='center'>
                                    <CircularProgress />
                                </TableCell>
                            </TableRow>
                        }

                    </TableBody>

                </Table>
            </TableContainer>
        </Layout>
    )
}

export default Ads