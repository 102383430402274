
import react, { useState, useEffect } from 'react'
import Layout from '../../Layout/Layout';
import { Breadcrumbs, Button, Modal, Typography, Box, Stack, CircularProgress } from '@mui/material';
import { Link } from 'react-router-dom';
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Service } from './../../config/service';
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: '13px',
    p: 4,

};

function Category() {
    const [category, setCategory] = useState()
    const token = localStorage.getItem('token')
    const [deleteCategory, setDeleteCategory] = useState({})
    async function fetchData() {
        const { Data } = await Service.getCategory(token)

        setCategory(Data)
    }
    useEffect(() => {

        fetchData()

    }, [])


    const [open, setOpen] = useState(false);

    const confirmDelete = (item) => {
        setDeleteCategory(item)
        setOpen(true)
    }
    const handleDelete = async (deletecategory) => {

        try {
            const { response, status, message } = await Service.deleteCategory(deletecategory._id, token)

            if (status === true && response === 200) {

                setOpen(false)
                toast.success(message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
                fetchData()
            }

        } catch (error) {
            console.log(error)
            toast.error("Something Wrong", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
        }

    };
    const handleClose = () => setOpen(false);

    return (
        <Layout>
            <div role="presentation" >
                <Breadcrumbs aria-label="breadcrumb"  >
                    <Link underline="hover" color="inherit" to="/">
                        Home
                    </Link>
                    <Link
                        underline="hover"
                        color="inherit"
                        to="/category"
                    >
                        Category
                    </Link>
                    <Typography color="text.primary">Category List</Typography>
                </Breadcrumbs>
            </div>
            <TableContainer component={Paper} sx={{ my: 5 }}>
                <Table  >
                    <TableHead >
                        <TableRow>
                            <TableCell style={{ fontWeight: '600' }}>#</TableCell>
                            <TableCell align="left" style={{ fontWeight: '600' }} >Name</TableCell>
                            <TableCell align="left" style={{ fontWeight: '600' }}>Total Sub Category</TableCell>
                            <TableCell align="left" style={{ fontWeight: '600' }}>Image</TableCell>
                            <TableCell align="right" sx={{ pr: 4, fontWeight: '600' }}>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {category ?
                            category.map((item, i) => {
                                return (
                                    <TableRow key={i}>
                                        <TableCell component="th" scope="row">
                                            {i + 1}
                                        </TableCell>
                                        <TableCell align="left">
                                            {item.name}
                                        </TableCell>
                                        <TableCell align="left">
                                            {item.count}
                                        </TableCell>
                                        <TableCell align="left">
                                            <img src={item.image} width='40px'></img>
                                        </TableCell>
                                        <TableCell align="right">
                                            <IconButton aria-label="view" size="small"
                                                component={Link}
                                                to={`/sub-category/${item.name}`}
                                                state={item} >
                                                <VisibilityIcon />

                                            </IconButton>
                                            <IconButton aria-label="edit" size="small"
                                                component={Link}
                                                to={`/category/update-category/${item._id}`}
                                                state={item} >
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton aria-label="delete" size="small"
                                                onClick={() => confirmDelete(item)
                                                } >
                                                <DeleteIcon />

                                            </IconButton>

                                            <Modal
                                                open={open}
                                                onClose={handleClose}
                                                aria-labelledby="modal-modal-title"
                                                aria-describedby="modal-modal-description"
                                                BackdropProps={{ style: { opacity: '0.1' } }}
                                            >
                                                <Box sx={style} >
                                                    <Typography variant="h5" component="h2"
                                                        sx={{ textAlign: 'center' }}>
                                                        Are You Sure To Delete ?
                                                    </Typography>

                                                    <Stack spacing={2} direction="row"
                                                        sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                                                        <Button variant="contained" color="error" onClick={() => handleDelete(deleteCategory)} >Yes</Button>
                                                        <Button variant="outlined" onClick={handleClose}>No</Button>

                                                    </Stack>
                                                </Box>
                                            </Modal>
                                        </TableCell>

                                    </TableRow>

                                )
                            }) :
                            <TableRow>
                                <TableCell />

                                <TableCell />


                                <TableCell align='center'>
                                    <CircularProgress />
                                </TableCell>
                                <TableCell />
                                <TableCell />
                            </TableRow>
                        }

                    </TableBody>

                </Table>
            </TableContainer>
            <ToastContainer />
        </Layout>
    )


}

export default Category

