
import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom';
import Category from './views/Category/Category';
import CreateCategory from './views/Category/Create_Category';
import CreateSubcategory from './views/Category/Create_Subcategory';
import Dashboard from './views/Dashboard/Dashboard';
import Profile from './views/Profile/Profile';
import Editcategory from './views/Category/edit/Editcategory';
import CreateAds from './views/Ads/CreateAds';
import EditAds from './views/Ads/edit/EditAds';
import Ads from './views/Ads/Ads';
import SubCategory from './views/Category/SubCategory';
import Editsubcategory from './views/Category/edit/Editsubcategory';
import Login from './components/Login/Login';
import { AppRoutes, PrivateRoutes } from './routes/routes';
import { useEffect } from 'react';
import useAuth from './hooks/useAuth';
import User from './views/Users/User';


function App() {
  const { verifyToken } = useAuth()
  const user = localStorage.getItem('token')

  useEffect(() => {
    verifyToken()
  }, []);


  return (
    // <BrowserRouter>
    //   <Routes>
    //     <Route path="/login" element={<Login/>} />
    //     <Route path="/" element={<Dashboard />} />
    //     <Route path="/category" element={<Category />} />
    //     <Route path="/category/create-category" element={<CreateCategory />} />
    //     <Route path="/category/update-category/:id" element={<Editcategory />} />
    //     <Route path="/sub-category/:name/" element={<SubCategory />} />
    //     <Route path="/sub-category/update-subcategory/:id/" element={<Editsubcategory />} />
    //     <Route path="/category/create-subcategory" element={<CreateSubcategory />} />
    //     <Route path="/ads" element={<Ads />} />
    //     <Route path="/ads/create-ads" element={<CreateAds />} />
    //     <Route path="/ads/edit-ads/:id" element={<EditAds />} />
    //     <Route path="/account/profile" element={<Profile />} />
    //     <Route path="/users" element={<User />} />
    //     <Route path="*" element={<h1>Not Found</h1>} />
    //   </Routes>
    // </BrowserRouter>
    <BrowserRouter>
      <Routes>
        {AppRoutes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            element={route.component}
          />
        ))}
         <Route element={!user ? <Outlet /> : <Navigate to="/dashboard" />}>
            <Route path='/login' element={<Login />} />
        </Route>
        <Route element={user ? <Outlet /> : <Navigate to="/login" />}>
          {PrivateRoutes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={route.component}
            />
          ))}
        </Route>

      </Routes>


    </BrowserRouter>
  )

}
export default App
