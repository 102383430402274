import { Box, Breadcrumbs, Button, Divider, FormControl, FormControlLabel, IconButton, InputAdornment, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField, Typography } from '@mui/material'
import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import Layout from '../../Layout/Layout'
import PhotoSizeSelectActualIcon from '@mui/icons-material/PhotoSizeSelectActual';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import { useForm } from 'react-hook-form';
import CloseIcon from '@mui/icons-material/Close';

const CreateAds = () => {
    const { register, handleSubmit, formState: { errors } } = useForm({});
    const hiddenFileInput = useRef(null);

    
    const [images, setImages] = useState([]);

    const handleClick = event => {
        hiddenFileInput.current.click();
    };

    const handleImage = async (e) => {

        for (let i = 0; i < e.target.files.length; i++) {
            let reader = new FileReader();
            let file = e.target.files[i];
            reader.readAsDataURL(file);
            reader.onload = () => {
                console.log(reader.result);
                setImages([...images,reader.result])
            }

        }




    }

    const removeImage = (i) => {

        const filteredArray = images.filter((image, index) => index !== i);
        setImages(filteredArray)


    }
    const submitForm = async (data) => {
        console.log(data)
        
    }

    return (
        <Layout>
            <Box role="presentation" sx={{ mb: 6 }}>
                <Breadcrumbs aria-label="breadcrumb" >
                    <Link underline="hover" color="inherit" to="/">
                        Home
                    </Link>
                    <Link
                        underline="hover"
                        color="inherit"
                        to="/ads"
                    >
                        Ads
                    </Link>
                    <Typography color="inherit" >Create Ads</Typography>
                </Breadcrumbs>
            </Box>
            <Typography variant="h5">Create New Ads</Typography>
            <form onSubmit={handleSubmit(submitForm)}>
                <Box p={7} >
                    <Button onClick={handleClick} sx={{ bgcolor: "#3B5998", height: "40%", width: "100%", display: "flex", justifyContent: "center", mb: 4 }}>
                        <Typography sx={{ py: 6, color: "#FFFFFF" }}>
                            <PhotoSizeSelectActualIcon sx={{ fontSize: '57px', mr: 2 }} />
                            <AddCircleIcon sx={{ fontSize: '37px', mr: 2, mb: 1 }} />
                        </Typography>
                        <Typography sx={{ py: 6, color: "#FFFFFF", mt: 1.5, fontWeight: 300 }}>Add Images</Typography>
                    </Button>
                    <input type="file" multiple
                        ref={hiddenFileInput}
                        onChange={handleImage}
                        style={{ display: 'none' }}
                    />
                    <Box sx={{ display: 'flex' }}>

                        {(images || []).map((url, i) => (
                            <Box key={i}>
                                <img src={url} alt="..." width='100px' height='100px' style={{ padding: 7 }} />

                                <IconButton component="span"
                                    disableRipple
                                    sx={{
                                        position: 'relative',
                                        left: '-19%',
                                        bottom: "65%",
                                        bgcolor: '#F2951F', padding: "0px"
                                    }} onClick={() => removeImage(i)}>

                                    <CloseIcon sx={{ color: 'white', fontSize: '15px' }} />
                                </IconButton>
                                {i === 0 &&
                                    <Typography sx={{ bgcolor: '#3B5998', color: "white", width: '100px', textAlign: 'center', position: 'relative', bottom: '22%', marginLeft: '6px', opacity: '0.9' }}>Cover</Typography>
                                }

                            </Box>
                        ))}
                    </Box>
                    <Typography
                        sx={{ color: "#484848", fontWeight: 600, fontSize: "20px", mb: 2 }}>

                        Ad Title

                    </Typography>

                    <TextField variant="outlined" sx={{ width: '100%', mb: '6px' }}
                        {...register('title', {
                            required: "Please Enter Ad Title",

                        })} />
                    {errors.title && <span role="alert" style={{ color: 'red' }}>{errors.title.message}</span>}
                    <Typography sx={{ fontSize: "13px", color: '#707070', paddingBottom: 4 }}>
                        Mention the key features of your item (e.g. brand, model, age, type)
                    </Typography>
                    <Typography sx={{ color: "#484848", fontWeight: 600, fontSize: "20px", mb: 2 }}>

                        Description

                    </Typography>
                    <TextField variant="outlined" multiline rows={5}
                        {...register('description', {
                            required: "Please Enter description",

                        })} fullWidth sx={{ width: '100%', borderRadius: 4, mb: '6px' }} />
                    {errors.description && <span role="alert" style={{ color: 'red' }}>{errors.description.message}</span>}
                    <Typography sx={{ fontSize: "13px", color: '#707070', paddingBottom: 4 }}>
                        Include condition, features and reason for selling
                    </Typography>
                    <Typography sx={{ color: "#484848", fontWeight: 600, fontSize: "20px" }}>

                        Furnished

                    </Typography>
                    <Box sx={{ display: "flex", mt: 2 }}>
                        <FormControl>

                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue="none"
                                name="radio-buttons-group"
                                row
                                {...register('type', {
                                    required: "Please select anyone",

                                })}
                            >
                                <FormControlLabel value="furnished" control={<Radio />} label="Furnished" />
                                <FormControlLabel value="unfurnished" control={<Radio />} label="UnFurnished" />

                            </RadioGroup>
                            {errors.type && <span role="alert" style={{ color: 'red' }}>{errors.type.message}</span>}
                        </FormControl>



                    </Box>
                    <Divider />
                    <FormControl sx={{ width: '50%', marginY: 3 }}>
                        <InputLabel id="demo-controlled-open-select-label">Bedrooms</InputLabel>
                        <Select
                            label=" Bedrooms"
                            defaultValue="" 
                            labelId="demo-multiple-name-label"
                            id="demo-multiple-name"
                            sx={{ mr: 3 }}
                            {...register('bedroom', {
                                required: "Please select category",

                            })}
                        >


                            <MenuItem value='Bedrooms'>Bedrooms</MenuItem>
                            <MenuItem value='Dining'>Dining</MenuItem>
                            <MenuItem value='Drawingroom'>Drawingroom</MenuItem>


                        </Select>
                        {errors.bedroom && <span role="alert" style={{ color: 'red' }}>{errors.bedroom.message}</span>}
                    </FormControl>
                    <FormControl sx={{ width: '50%', marginY: 3 }}>
                        <InputLabel id="demo-controlled-open-select-label">Bathrooms</InputLabel>
                        <Select
                            label=" Bathrooms"
                            defaultValue=""
                            labelId="demo-multiple-name-label"
                            id="demo-multiple-name"
                            {...register('bathroom', {
                                required: "Please select",

                            })}
                        >
                            <MenuItem value='b1'>b1</MenuItem>
                            <MenuItem value='b2'>b2</MenuItem>
                            <MenuItem value='b3'>b3</MenuItem>


                        </Select>
                        {errors.bathroom && <span role="alert" style={{ color: 'red' }}>{errors.bathroom.message}</span>}
                    </FormControl>
                    <FormControl sx={{ width: '50%', marginY: 3 }}>

                        <Typography sx={{ color: "#484848", fontSize: "20px", fontWeight: 600 }}> Area Unit*</Typography>

                        <Select
                            defaultValue=""
                            labelId="demo-multiple-name-label"
                            id="demo-multiple-name"
                            sx={{ mr: 3 }}
                            {...register('marla', {
                                required: "Please select unit",

                            })}
                        >

                            <MenuItem value="acre">acre</MenuItem>
                            <MenuItem value="marla">marla</MenuItem>
                            <MenuItem value="feet">feet</MenuItem>


                        </Select>
                        {errors.marla && <span role="alert" style={{ color: 'red' }}>{errors.marla.message}</span>}
                    </FormControl>
                    <FormControl sx={{ width: '50%', marginY: 3 }}>
                        <Typography sx={{ color: "#484848", fontSize: "20px", fontWeight: 600 }}> Area*</Typography>

                        <Select
                            defaultValue=""
                            labelId="demo-multiple-name-label"
                            id="demo-multiple-name"
                            {...register('area', {
                                required: "Please select area",

                            })}
                        >
                            <MenuItem value='a1'>a1</MenuItem>
                            <MenuItem value='a2'>a2</MenuItem>
                            <MenuItem value='a3'>a3</MenuItem>


                        </Select>
                        {errors.area && <span role="alert" style={{ color: 'red' }}>{errors.area.message}</span>}
                    </FormControl>


                    <Divider />
                    <Typography sx={{ color: "#484848", fontWeight: 600, fontSize: "20px", paddingY: 2 }}>

                        Set A Price

                    </Typography>
                    <TextField label="Your Price Here" variant="outlined"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <IconButton>
                                        <LocalOfferOutlinedIcon sx={{ fontSize: '27px', mr: 2, color: "#3B5998" }} />
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}

                        {...register('price', {
                            required: "Please Enter price",

                        })}

                        sx={{ width: '100%', mb: '6px' }} >   </TextField>
                    {errors.price && <span role="alert" style={{ color: 'red' }}>{errors.price.message}</span>}

                    <Typography sx={{ color: "#484848", fontWeight: 600, fontSize: "20px", paddingY: 2 }}>

                        YOUR AD'S LOCATION

                    </Typography>
                    <Box sx={{ display: "flex", marginY: 2 }}>

                        <Select

                            defaultValue=""
                            labelId="demo-multiple-name-label"
                            id="demo-multiple-name"
                            sx={{ width: "50%", mr: 3 }}

                            {...register('location', {
                                required: "Please select location",

                            })}

                        >


                            <MenuItem value='karachi'>Karachi</MenuItem>
                            <MenuItem value='lahore'>Lahore</MenuItem>
                            <MenuItem value='multan'>Multan</MenuItem>


                        </Select>

                        {errors.location && <span role="alert" style={{ color: 'red' }}>{errors.location.message}</span>}

                        <Select
                            labelId="demo-multiple-name-label"
                            id="demo-multiple-name"
                            defaultValue="" 
                            sx={{ width: "50%" }}
                            {...register('localarea', {
                                required: "Please select local area",

                            })}
                        >
                            <MenuItem value='DHA'>DHA</MenuItem>
                            <MenuItem value='Defence'>Defence</MenuItem>
                            <MenuItem value='Clifton'>Clifton</MenuItem>


                        </Select>

                        {errors.localarea && <span role="alert" style={{ color: 'red' }}>{errors.localarea.message}</span>}
                    </Box>
                    <Box >
                        <Button type='submit' variant="contained" sx={{ width: "48.9%", p: 2.5, backgroundColor: "#3B5998", marginY: 4, borderRadius: 2.4 }}>Create Ad</Button>
                    </Box>
                </Box>
            </form>
        </Layout>
    )
}

export default CreateAds