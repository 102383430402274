import { Box, Breadcrumbs, Button, FormControl, FormLabel, TextField, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import Layout from './../../Layout/Layout';
import { Service } from '../../config/service';
import React, { useEffect, useState } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';






const Profile = () => {
    const [user, setUser] = useState();
    const token = localStorage.getItem('token')

    const fetchUser = async () => {
        try {
            const { Data } = await Service.specificProfile(token);
            console.log(Data)
            setUser(Data)
        } catch (error) {
            console.log('file: User.js => line 30 => fetchUser => error', error)
        }
    }

    const updateData = async () => {
        try {

            const formData = new FormData(document.querySelector('#detailForm'));
            const userData = {};
            formData.forEach((value, key) => {
              userData[key] = value;
            });
            const { response, status, message }= await Service.userProfileUpdate(userData,token);
            if (status === true && response === 200) {
             
                toast.success(message, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
             
            }
        } catch (error) {
            console.log(error);
        }
    };

    const changePassword = async () => {
        try {

            const formData = new FormData(document.querySelector('#passwordForm'));
            const userData = {};
            formData.forEach((value, key) => {
              userData[key] = value;
            });
            const data= await Service.change_password(userData,token);
            console.log(data)
            if (data.response === 200) {
             
                toast.success(data.message, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
             
            }
            else{
                toast.error(data.message, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchUser()
    }, [])

    return (
        <Layout>
            <Box role="presentation" >
                <Breadcrumbs aria-label="breadcrumb"  >
                    <Link underline="hover" color="inherit" to="/">
                        Home
                    </Link>
                    <Link
                        underline="hover"
                        color="inherit"
                        to="/account"
                    >
                        Account
                    </Link>
                    <Typography color="text.primary">Profile</Typography>
                </Breadcrumbs>
            </Box>
            {/* <Box sx={{borderRadius:'25px',bgcolor:'white'}}>
                hello
            </Box> */}

            <Box sx={{ borderRadius: '25px', bgcolor: 'white', p: 3 }}>
                <Typography variant='h6'><strong>Basic Info</strong></Typography>
                <form id='detailForm'>
                    <FormControl fullWidth sx={{ my: 2 }}>
                        <FormLabel>Name</FormLabel>
                        <TextField name="name" placeholder='Enter Name' value={user?.name} variant="standard" onChange={(event) => setUser({ ...user, name: event.target.value })} />
                    </FormControl>
                    <FormControl fullWidth sx={{ my: 2 }}>
                        <FormLabel>Email</FormLabel>
                        <TextField name="email" placeholder='Enter Email' value={user?.email} variant="standard" />
                    </FormControl>
                    <FormControl fullWidth sx={{ my: 2 }}>
                        <FormLabel>Phone No.</FormLabel>
                        <TextField name="phoneno" placeholder='Enter Phone No.' value={user?.phoneno} variant="standard" />
                    </FormControl>
                    <Button variant="contained" sx={{ bgcolor: '#212121', mt: 2 }} onClick={updateData}>
                        Update
                    </Button>
                </form>
            </Box>
            <Box sx={{ borderRadius: '25px', bgcolor: 'white', p: 3, mt: 2 }}>
                <Typography variant='h6'><strong>Change Password</strong></Typography>
                <form id="passwordForm">
                    <FormControl fullWidth sx={{ my: 2 }}>
                        <TextField name="password" type='password' id="outlined-basic" label="Current Password" variant="outlined" />
                    </FormControl>
                    <FormControl fullWidth sx={{ my: 2 }}>
                        <TextField id="outlined-basic" type='password' label="New Password" variant="outlined" />
                    </FormControl>
                    <FormControl fullWidth sx={{ my: 2 }}>
                        <TextField name="new_password" type='password' id="outlined-basic" label="Confirm Password" variant="outlined" />
                    </FormControl>
                    <Button variant="contained" sx={{ bgcolor: '#212121', mt: 2 }} onClick={changePassword}>
                    Change Password
                    </Button>
                 
                </form>
            </Box>

            <ToastContainer />
        </Layout>
    )
}


export default Profile