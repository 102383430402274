
import { Breadcrumbs, Button, Divider, FormControl, Grid, IconButton, InputLabel, MenuItem, Paper, Select, TextField, Typography } from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Box } from '@mui/material/';
import CloseIcon from '@mui/icons-material/Close';
import { useForm } from 'react-hook-form';
import { useEffect, useRef, useState } from 'react';
import Layout from '../../../Layout/Layout';
import { toast, ToastContainer } from 'react-toastify';
import { Service } from '../../../config/service';
import 'react-toastify/dist/ReactToastify.css';
function Editsubcategory() {
    const { state } = useLocation()
    const token = localStorage.getItem('token')
    const [category, setCategory] = useState([])
    async function fetchData() {
        const { Data } = await Service.getCategory(token)

        setCategory(Data)
    }
    useEffect(() => {

        fetchData()

    }, [])

    const [subCategory, setSubCategory] = useState(state)
    const { register, handleSubmit, formState: { errors }, reset, clearErrors } = useForm({});
    const [image, setImage] = useState();
    let navigate = useNavigate();
    const ref = useRef();

    // *For Upload Image
    const handleImage = (e) => {
        let file = e.target.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {

            setSubCategory({ image: reader.result })
        }
    }
    const handleChange = (event) => {
        clearErrors('category')
    };
    const removeImage = (e) => {
        setSubCategory({ image })
        ref.current.value = ''
    }

    const submitForm = async (data) => {

        try {
            const { response, status, message } = await Service.updateCategory(state._id, { name: data.name, image: subCategory.image }, token)
            navigate('/category')
            // if (status === true && response === 200) {
            //     reset({
            //         name: '',
            //         image: ''
            //     })
            //     setSubCategory({image:''})
            //     toast.success(message, {
            //         position: "top-right",
            //         autoClose: 5000,
            //         hideProgressBar: false,
            //         closeOnClick: true,
            //         pauseOnHover: true,
            //         draggable: true
            //     });
            // }

        } catch (error) {
            console.log(error)
            toast.error("Something Wrong", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
        }
    }
    return (
        <Layout>
            <Box role="presentation" sx={{ mb: 6 }}>
                <Breadcrumbs aria-label="breadcrumb" >
                    <Link underline="hover" color="inherit" to="/">
                        Home
                    </Link>
                    <Link
                        underline="hover"
                        color="inherit"
                        to="/subcategory"
                    >
                        SubCategory
                    </Link>
                    <Typography color="inherit" >Edit SubCategory</Typography>
                </Breadcrumbs>
            </Box>
            <Typography variant="h5">Edit SubCategory</Typography>
            <Box sx={{
                height: '600px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <Paper sx={{
                    width: '550px', borderRadius: '10px', boxShadow: 4
                }} >
                    <form onSubmit={handleSubmit(submitForm)}>
                        <Grid container sx={{ p: 2 }}>
                            <Grid item lg={12} xs={12}>
                                <FormControl fullWidth>
                                    <Box sx={{ m: 2, bgcolor: '#fffe', borderRadius: '12px' }}>
                                        <TextField label="SubCategory name" fullWidth
                                            defaultValue={subCategory.name}
                                            variant="outlined"
                                            {...register("name", {
                                                required: "Category name is required",

                                            })} />
                                        {errors.name && <span role="alert" style={{ color: 'red' }}>{errors.name.message}</span>}
                                    </Box>

                                </FormControl>
                            </Grid>

                            <Grid item lg={12} xs={12}>
                                <Box sx={{ m: 2, bgcolor: '#fffe', borderRadius: '12px' }}>
                                    <FormControl fullWidth >

                                        <InputLabel >Category</InputLabel>
                                        <Select
                                            {...register("category", {
                                                required: "Select Category",

                                            })}
                                            defaultValue={subCategory.parent_Id}
                                            label="Category"
                                            onChange={handleChange}
                                        >
                                            {category.map((data) => {
                                                return (
                                                    <MenuItem value={data._id} key={data.id} >{data.name}</MenuItem>
                                                )
                                            }
                                            )}


                                        </Select>
                                    </FormControl>
                                    {errors.category && <span role="alert" style={{ color: 'red' }}>{errors.category.message}</span>}
                                </Box>
                            </Grid>
                            <Grid item lg={12} xs={12}>
                                <Box sx={{ m: 2, bgcolor: '#fffe', borderRadius: '12px' }}>
                                    <FormControl fullWidth>
                                        <TextField type='file' fullWidth
                                            inputRef={ref}
                                            variant="outlined"

                                            {...register("image", {
                                                required: "required",

                                            })}

                                            onChange={(e) => handleImage(e)}

                                        />


                                    </FormControl>
                                    {errors.image && <span role="alert" style={{ color: 'red' }}>{errors.image.message}</span>}
                                </Box>

                            </Grid>
                            <Grid item lg={12} xs={12}>
                                <FormControl fullWidth>
                                    <Box sx={{ m: 2 }}>
                                        {subCategory.image && (
                                            <div>
                                                <img
                                                    src={subCategory.image}
                                                    alt="category" width='100px' height='100px'
                                                />
                                                <IconButton component="span"
                                                    disableRipple
                                                    sx={{
                                                        position: 'absolute',
                                                        left: '20%', top: '2%',
                                                        bgcolor: '#f7f7f7', padding: "1px"
                                                    }} onClick={() => removeImage()}>

                                                    <CloseIcon />
                                                </IconButton>

                                            </div>
                                        )}
                                    </Box>
                                </FormControl>
                            </Grid>



                        </Grid>
                        <Button type="submit" variant="contained" sx={{ bgcolor: '#3B5998', px: 3, py: 1, borderRadius: 2, float: 'right', m: 2 }} >Update</Button>
                    </form>
                </Paper>
            </Box>
            <ToastContainer />

        </Layout>
    )
}

export default Editsubcategory