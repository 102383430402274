

import Layout from '../../Layout/Layout';
import { Typography, Container, Box, Grid } from '@mui/material';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { BarChart } from '@mui/icons-material';
import { Chart } from 'chart.js';

function Dashboard() {

    const [chartData, setChartData] = useState({})
    return (
        <Layout>
            <Typography sx={{ ml: 1 }}>
                Dashboard
            </Typography>

        </Layout>
    )


}
export default Dashboard

