import { Box, Breadcrumbs, Button, CircularProgress, IconButton, Modal, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import DeleteIcon from '@mui/icons-material/Delete';
import Layout from '../../Layout/Layout'
import { Service } from '../../config/service';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: '13px',
    p: 4,

};

const User = () => {
    const [user, setUser] = useState();
    const [open, setOpen] = useState(false);
    const [userId, setUserId] = useState();

    const token = localStorage.getItem('token')

    const fetchUser = async () => {
        try {
            const { Data } = await Service.getUser(token);
            setUser(Data)
        } catch (error) {
            console.log('file: User.js => line 30 => fetchUser => error', error)
        }
    }

    useEffect(() => {
        fetchUser()
    }, [])

    const handleDelete = async () => {

        try {
            const { response, status, message } = await Service.deleteUser(userId, token)

            if (status === true && response === 200) {

                setOpen(false)
                toast.success(message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
                fetchUser()
            }

        } catch (error) {
            console.log(error)
        }

    };
    return (
        <Layout>
            <div role="presentation" >
                <Breadcrumbs aria-label="breadcrumb"  >
                    <Link underline="hover" color="inherit" to="/">
                        Home
                    </Link>
                    <Link
                        underline="hover"
                        color="inherit"
                        to="/User"
                    >
                        User
                    </Link>
                    <Typography color="text.primary">User List</Typography>
                </Breadcrumbs>
            </div>
            <TableContainer component={Paper} sx={{ my: 5 }}>
                <Table  >
                    <TableHead >
                        <TableRow>
                            <TableCell style={{ fontWeight: '600' }}>#</TableCell>
                            <TableCell align="left" style={{ fontWeight: '600' }} >Name</TableCell>
                            <TableCell align="left" style={{ fontWeight: '600' }}>Email</TableCell>
                            <TableCell align="left" style={{ fontWeight: '600' }}>Phone Number</TableCell>
                            <TableCell align="left" style={{ fontWeight: '600' }}>Image</TableCell>
                            <TableCell align="right" sx={{ pr: 4, fontWeight: '600' }}>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {user ?
                            user.map((item, i) => {
                                return (
                                    <TableRow key={i}>
                                        <TableCell component="th" scope="row">
                                            {i + 1}
                                        </TableCell>
                                        <TableCell align="left">
                                            {item.name}
                                        </TableCell>
                                        <TableCell align="left">
                                            {item.email}
                                        </TableCell>
                                        <TableCell align="left">
                                            {item.phoneno}
                                        </TableCell>
                                        <TableCell align="left">
                                            <img src={item.image} width='40px'></img>
                                        </TableCell>
                                        <TableCell align="right">

                                            <IconButton aria-label="delete" size="small"
                                                onClick={() => { setOpen(true); setUserId(item._id) }} >
                                                <DeleteIcon />

                                            </IconButton>

                                            <Modal
                                                open={open}
                                                onClose={() => setOpen(false)}
                                                aria-labelledby="modal-modal-title"
                                                aria-describedby="modal-modal-description"
                                                BackdropProps={{ style: { opacity: '0.1' } }}
                                            >
                                                <Box sx={style} >
                                                    <Typography variant="h5" component="h2"
                                                        sx={{ textAlign: 'center' }}>
                                                        Are You Sure To Delete ?
                                                    </Typography>

                                                    <Stack spacing={2} direction="row"
                                                        sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                                                        <Button variant="contained" color="error"
                                                            onClick={() => handleDelete()}>Yes
                                                        </Button>
                                                        <Button variant="outlined" onClick={() => setOpen(false)}>No</Button>

                                                    </Stack>
                                                </Box>
                                            </Modal>
                                        </TableCell>

                                    </TableRow>

                                )
                            }) :
                            <TableRow>
                                <TableCell colSpan={6} align='center'>
                                    <CircularProgress />
                                </TableCell>
                            </TableRow>
                        }

                    </TableBody>

                </Table>
            </TableContainer>
            <ToastContainer />
        </Layout>
    )
}

export default User