import { Navigate } from 'react-router-dom';

// *Import Components
import Category from '../views/Category/Category';
import CreateCategory from '../views/Category/Create_Category';
import CreateSubcategory from '../views/Category/Create_Subcategory';
import Dashboard from '../views/Dashboard/Dashboard';
import Profile from '../views/Profile/Profile';
import Editcategory from '../views/Category/edit/Editcategory';
import CreateAds from '../views/Ads/CreateAds';
import EditAds from '../views/Ads/edit/EditAds';
import Ads from '../views/Ads/Ads';
import SubCategory from '../views/Category/SubCategory';
import Editsubcategory from '../views/Category/edit/Editsubcategory';
import Login from '../components/Login/Login';
import User from '../views/Users/User';


export const AppRoutes = [
    {
        path: "/",
        component: <Navigate to="/dashboard" />
    },
    {
        path: "*",
        component: <Navigate to="/404" />
    },
    
]

export const PrivateRoutes = [
    {
        
        path: "/dashboard",
        component: <Dashboard />
    },
    {
        
        path: "/category",
        component: <Category />
    },
    {
        
        path: "/category/create-category",
        component:<CreateCategory />
    },
    {
        
        path: "/category/update-category/:id",
        component: <Editcategory />
    },
    {
        
        path: "/sub-category/:name/",
        component: <SubCategory />
    },
    {
        
        path: "/sub-category/:name/",
        component: <SubCategory />
    },

    {
        
        path: "/sub-category/update-subcategory/:id/",
        component: <Editsubcategory />
    },
    {
        
        path: "/category/create-subcategory",
        component: <CreateSubcategory />
    },
    {
        
        path: "/ads",
        component: <Ads />
    },
    {
        
        path: "/ads/create-ads",
        component: <CreateAds />
    },
    {
        
        path: "/ads/edit-ads/:id",
        component: <EditAds />
    },
    {
        
        path: "/account/profile",
        component: <Profile />
    },
    {
        
        path: "/users",
        component: <User />
    },

    
    
]
