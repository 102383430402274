import React from 'react'
import { Box, Button, FormControl, Paper, TextField } from '@mui/material';
import logo from '../../assets/logo.png'
import { useForm } from 'react-hook-form';
import useAuth from '../../hooks/useAuth';
import { Service } from './../../config/service';
import { toast, ToastContainer } from 'react-toastify';

const Login = () => {
  const { register, handleSubmit, formState: { errors } } = useForm({});
  const { signIn } = useAuth()

  const submitForm = async (data) => {

    try {
      let obj = {
        email: data.email,
        password: data.password,
        type: 'admin'

      }
      const { response, status, message, Data } = await Service.login(obj)
      signIn(Data.name, Data.tokens, Data.image)
    } catch (error) {
      toast.error(error, {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
    }

  }
  return (
    <>

      <Box
        sx={{
          position: 'absolute',
          transform: 'translate(-50%,-50%)',
          top: '50%',
          left: '50%',
          '& > :not(style)': {
            m: 1,
            width: 395,
            padding: '24px',
            borderRadius: '12px',
            boxShadow: 5
          },
        }}
      >
        <Paper elevation={0}>
          <Box sx={{ textAlign: 'center', mb: 2 }}>
            <img src={logo} alt='logo' />
          </Box>
          <form onSubmit={handleSubmit(submitForm)}>
            <FormControl fullWidth sx={{ mb: 2 }} >
              <TextField label="Email" type='email' variant="outlined" size='small'
                {...register('email', {
                  required: "Please Enter Email",
                })}
              />
            </FormControl >
            <FormControl fullWidth sx={{ mb: 2 }}>
              <TextField label="Password" type='password' variant="outlined" size='small'
                {...register('password', {
                  required: "Please Enter Password",
                })} />
            </FormControl >
            <Button type='submit' variant="contained" fullWidth>Login</Button>
          </form>
        </Paper>

      </Box>
      <ToastContainer />
    </>
  )
}

export default Login