import { Apis, post, get, put, deleted } from '.';


export const Service = {
    addCategory: async (obj, token) => {
        let result = await post(Apis.addCategory, obj, token);
        if (result.status === 201) return result.data;
        else throw result;
    },
    getCategory: async (token) => {
        let result = await get(Apis.getCategory, token);
        if (result.status === 201) return result.data;
        else throw result;
    },
    deleteCategory: async (id, token) => {
        let result = await deleted(Apis.deleteCategory + `/${id}`, token);
        if (result.status === 201) return result.data;
        else throw result;
    },
    updateCategory: async (id, obj, token) => {
        let result = await put(Apis.updateCategory + `/${id}`, obj, token);
        if (result.status === 201) return result.data;
        else throw result;
    },
    getSubCategory: async (id, token) => {
        let result = await get(Apis.getSubCategory + `?cat_id=${id}`, token);
        if (result.status === 201) return result.data;
        else throw result;
    },
    getAds: async (token) => {
        let result = await get(Apis.getAds, token);
        if (result.status === 201) return result.data;
        else throw result;
    },
    insertImage: async (obj, token) => {
        let result = await post(Apis.insertImage, obj, token);
        if (result.status === 201) return result.data;
        else throw result;
    },
    login: async (obj) => {
        let result = await post(Apis.login, obj);
        if (result.status === 200) return result.data;
        else throw result;
    },
    getUser: async (token) => {
        let result = await get(Apis.users, token);
        if (result.status === 201) return result.data;
        else throw result;
    },
    specificProfile: async (token) => {
        let result = await get(Apis.specificProfile, token);
        if (result.status === 200) return result.data;
        else throw result;
    },
    userProfileUpdate: async (obj, token) => {

        let result = await put(Apis.userProfileUpdate, obj, token);
        if (result.status === 201) return result.data;
        else throw result;
    },
    getSpecificUser: async (id,token) => {
        let result = await get(Apis.specificUser+`/${id}`, token);
        if (result.status === 201) return result.data;
        else throw result;
    },
    deleteUser: async (id, token) => {
        let result = await deleted(Apis.deleteUser + `/${id}`, token);
        if (result.status === 201) return result.data;
        else throw result;
    },
    change_password: async (obj, token) => {
        let result = await post(Apis.change_password, obj, token);
        if (result.status === 200) return result.data;
        else throw result;
    },
}