

import Layout from '../../Layout/Layout';
import { Breadcrumbs, Button, Divider, FormControl, Grid, IconButton, Paper, TextField, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { Box } from '@mui/material/';
import { useRef, useState } from 'react';
import { useForm } from "react-hook-form";
import CloseIcon from '@mui/icons-material/Close';
import { Service } from './../../config/service';
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
function CreateCategory() {

    const { register, handleSubmit, formState: { errors }, reset } = useForm({});
    const [image, setImage] = useState();
    const token = localStorage.getItem('token')

    const ref = useRef();

    // *For Upload Image
    const handleImage = (e) => {
        let file = e.target.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            setImage(reader.result)
        }
    }

    const removeImage = (e) => {
        setImage()
        ref.current.value = ''
    }


    const submitForm = async (data) => {

        try {
            const { response, status, message } = await Service.addCategory({ name: data.name, image: image }, token)

            if (status === true && response === 200) {
                reset({
                    name: '',
                    image: ''
                })
                setImage()
                toast.success(message, {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
            }

        } catch (error) {
            console.log(error)
            toast.error("Something Wrong", {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
        }
    }

    return (
        <>
            <Layout>
                <Box role="presentation" sx={{ mb: 6 }}>
                    <Breadcrumbs aria-label="breadcrumb" >
                        <Link underline="hover" color="inherit" to="/">
                            Home
                        </Link>
                        <Link
                            underline="hover"
                            color="inherit"
                            to="/category"
                        >
                            Category
                        </Link>
                        <Typography color="inherit" >Create Category</Typography>
                    </Breadcrumbs>
                </Box>
                <Typography variant="h5">Create New Category</Typography>
                <Box sx={{
                    height: '600px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    <Paper sx={{
                        width: '550px', borderRadius: '10px', boxShadow: 4
                    }} >
                        <form onSubmit={handleSubmit(submitForm)}>
                            <Grid container sx={{ p: 2 }}>
                                <Grid item lg={12} xs={12}>
                                    <FormControl fullWidth>
                                        <Box sx={{ m: 2, bgcolor: '#fffe', borderRadius: '12px' }}>
                                            <TextField label="Category name" fullWidth
                                                variant="outlined"
                                                {...register("name", {
                                                    required: "Category name is required",

                                                })} />
                                            {errors.name && <span role="alert" style={{ color: 'red' }}>{errors.name.message}</span>}
                                        </Box>

                                    </FormControl>
                                </Grid>
                                <Grid item lg={12} xs={12}>
                                    <FormControl fullWidth>
                                        <Box sx={{ ml: 2, bgcolor: '#fffe', borderRadius: '12px' }}>
                                            <Typography variant='p' sx={{ fontWeight: '600' }}>Recommend Size 100x100</Typography>
                                            <Divider sx={{ mt: 1, width: '97%' }} />
                                        </Box>
                                    </FormControl>

                                </Grid>

                                <Grid item lg={12} xs={12}>
                                    <Box sx={{ m: 2, bgcolor: '#fffe', borderRadius: '12px' }}>
                                        <FormControl fullWidth>
                                            <TextField type='file' fullWidth
                                                inputRef={ref}
                                                variant="outlined"
                                                {...register("image", {
                                                    required: "required",

                                                })}
                                                onChange={(e) => handleImage(e)}
                                            />


                                        </FormControl>
                                        {errors.image && <span role="alert" style={{ color: 'red' }}>{errors.image.message}</span>}
                                    </Box>

                                </Grid>
                                <Grid item lg={12} xs={12}>
                                    <FormControl fullWidth>
                                        <Box sx={{ m: 2 }}>
                                            {image && (
                                                <div>
                                                    <img
                                                        src={image}
                                                        alt="category" width='100px' height='100px'
                                                    />
                                                    <IconButton component="span"
                                                        disableRipple
                                                        sx={{
                                                            position: 'absolute',
                                                            left: '20%', top: '2%',
                                                            bgcolor: '#f7f7f7', padding: "1px"
                                                        }} onClick={() => removeImage()}>

                                                        <CloseIcon />
                                                    </IconButton>

                                                </div>
                                            )}
                                        </Box>
                                    </FormControl>
                                </Grid>



                            </Grid>
                            <Button type="submit" variant="contained" sx={{ bgcolor: '#3B5998', px: 3, py: 1, borderRadius: 2, float: 'right', m: 2 }} >Create</Button>
                        </form>
                    </Paper>
                </Box>


            </Layout>
            <ToastContainer />
        </>
    )


}

export default CreateCategory

